<template>
	<div>
		<el-drawer
			title="我是标题"
			:visible.sync="drawer"
			:direction="direction"
			:before-close="resetForm"
			:with-header="false"
		>
			<div class="titlebox">
				<span>编辑线索</span>
				<i @click="resetForm" class="el-icon-close" style="color: #000000;font-size: 20px;"></i>
			</div>
			<div class="formbox">
				<el-form
					:model="ruleForm"
					:rules="rules"
					ref="ruleForm"
					label-width="0"
					class="demo-ruleForm"
				>
					<el-form-item label="" prop="customerName">
						<div class="inputtitle">
							公司名称
						</div>
						<div class="inputbox">
							<el-input v-model="ruleForm.customerName" placeholder="请输入公司名称"></el-input>
						</div>
					</el-form-item>
					
					<el-form-item label="" prop="contactParams">
						<div class="inputtitle" style="margin-top: 25px;">
							<span>*</span>
							联系人
						</div>
						<div class="onerelation" v-for="(item, index) in ruleForm.contactParams" :key="index">
							<span>联系人{{index+1}}：</span>
							<p>{{item.userName}}</p>
							<p>{{item.positionName}}</p>
							<p>{{item.contactPhone}}</p>
							<div class="flex" style="cursor: pointer;">
								<i class="el-icon-delete" style="color: #CCCCCC;margin-right: 10px;" @click="clickDeletePersonal(index)"></i>
								<!-- <div  @click="clickAddPersonal" v-if="ruleForm.contactParams.length == index + 1">
									<i class="el-icon-circle-plus-outline" style="color: #2E77F6;"></i>
									<span style="color: #2E77F6;margin: 0 0 0 10px;">添加(点击添加联系人)</span>
								</div> -->
							</div>
						</div>
						<div
							style="margin: 0 0 0 20px;cursor: pointer;"
							@click="clickAddPersonal"
							v-if="ruleForm.contactParams.length < 1"
						>
							<i class="el-icon-circle-plus-outline" style="color: #2E77F6;"></i>
							<span style="color: #2E77F6;margin: 0 0 0 10px;">添加(点击添加联系人)</span>
						</div>
						<div class="addinputbox">
							<span>联 系 人 ：</span>
							<el-input v-model="addpersoninfo.userName" placeholder="请输入联系人姓名"></el-input>
						</div>
						<div class="addinputbox">
							<span>担任职位：</span>
							<el-input v-model="addpersoninfo.positionName" placeholder="请输入联系人职务"></el-input>
						</div>
						<div class="addinputbox">
							<span>联系方式：</span>
							<el-input v-model="addpersoninfo.contactPhone" placeholder="请输入联系人电话"></el-input>
						</div>
					    <div style="cursor: pointer;margin: 20px 0 0 20px;	">
					    	<!-- <i class="el-icon-delete" style="color: #CCCCCC;margin-right: 10px;" @click="clickDeletePersonal(index)"></i> -->
					    	<div  @click="clickAddPersonal" >
					    		<i class="el-icon-circle-plus-outline" style="color: #2E77F6;"></i>
					    		<span style="color: #2E77F6;margin: 0px 0 0 10px;">添加(点击添加联系人)</span>
					    	</div>
					    </div>
					</el-form-item>

					<el-form-item label="" prop="address">
						<div class="inputtitle" style="margin-top: 25px;">
							
							公司地址
						</div>
						<div class="inputbox">
							<el-input v-model="ruleForm.address" placeholder="请输入公司地址"></el-input>
						</div>
					</el-form-item>

					<el-form-item label="" prop="companyType">
						<div class="inputtitle" style="margin-top: 25px;">
							
							公司类型
						</div>
						<div class="allway">
							<div
								class="oneway"
								v-for="(item, index) in companyTypeList"
								@click="chooseCompanyType(item)"
								:key="item.settingId"
							>
								<div :class="{ activeway: item.settingId === ruleForm.companyType }">
									<p v-if="item.settingId === ruleForm.companyType"></p>
								</div>
								<span>{{ item.settingName }}</span>
							</div>
						</div>
					</el-form-item>
					
					<el-form-item label="" prop="companyIndustry">
						<div class="inputtitle" style="margin-top: 25px;">
							
							所属行业
						</div>
						<div class="allway">
							<div
								class="oneway"
								v-for="(item, index) in companyIndustryList"
								@click="chooseCompanyIndustry(item)"
								:key="item.settingId"
							>
								<div :class="{ activeway: item.settingId === ruleForm.companyIndustry }">
									<p v-if="item.settingId === ruleForm.companyIndustry"></p>
								</div>
								<span>{{ item.settingName }}</span>
							</div>
						</div>
					</el-form-item>

					<el-form-item label="" prop="customerSource">
						<div class="inputtitle" style="margin-top: 25px;">
							
							线索来源
						</div>
						<div class="allway">
							<div
								class="oneway"
								v-for="(item, index) in cluesSourceList"
								@click="chooseClues(item)"
								:key="item.settingId"
							>
								<div :class="{ activeway: item.settingId === ruleForm.customerSource }">
									<p v-if="item.settingId === ruleForm.customerSource"></p>
								</div>
								<span>{{ item.settingName }}</span>
							</div>
						</div>
					</el-form-item>

					

					<el-form-item label="" prop="customerDesc">
						<div class="inputtitle" style="margin-top: 25px;">
							
							备注
						</div>
						<div class="textareabox">
							<el-input
								v-model="ruleForm.customerDesc"
								type="textarea"
								placeholder="请输入备注"
							></el-input>
						</div>
					</el-form-item>
				</el-form>
				<div class="twobtn">
					<div @click="resetForm()">取消</div>
					<div class="save" @click="submitForm()">保存</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { addCustomerInfo,editCustomerInfo } from '@/network/api.js';
export default {
	data() {
		return {
			addpersoninfo: {
				contactId: '', //联系人id
				contactPhone: '', //联系人手机号
				positionName: '', //联系人职务
				userName: '' //联系人姓名
			},
			ruleForm: {
				address: '', //公司地址
				companyIndustry: '', //所属行业id
				companyType: '', //公司类型id
				contactParams: [], //联系人
				customerDesc: '', // 公司简介
				customerId: '', // 客户id
				customerName: '', //公司名称
				customerSource: '', //线索来源
				customerStatus: '', //客户状态
				customerTag: '', // 标签id
				latitude: '', // 纬度
				longitude: '' // 经度
			},
			rules: {
				// address: [{ required: true, message: '请输入公司地址', trigger: 'blur' }],
				// customerDesc: [{ required: true, message: '请输入公司简介', trigger: 'blur' }],
				// companyType: [{ required: true, message: '请选择公司类型', trigger: 'blur' }],
				contactParams: [
					{ type: 'array', required: true, message: '请填写联系人', trigger: 'blur' }
				],
				// customerSource: [{ required: true, message: '请选择线索来源', trigger: 'blur' }],
				// companyIndustry: [{ required: true, message: '请选择公司行业', trigger: 'blur' }],
				// customerName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }]
			},
			drawer: false,
			direction: 'rtl',
			value1: '',
			wayIndex: 0
		};
	},
	computed: {
		// ...mapState(['companyTypeList', 'cluesSourceList','companyIndustryList'])
		...mapState('systemModule',{
			companyTypeList: 'companyTypeList',
			cluesSourceList: 'cluesSourceList',
			companyIndustryList: 'companyIndustryList'
		})
	},
	created() {},
	methods: {
		// 选择公司行业
		chooseCompanyIndustry(item) {
			this.ruleForm.companyIndustry = item.settingId
		},
		// 编辑客户详情
		async _editCustomerInfo() {
			await editCustomerInfo(this.ruleForm)
			this.$myMessage('编辑成功', 'success');
			this.resetForm();
			this.$emit('updataInfo');
		},
		// 点击删除联系人
		clickDeletePersonal(index) {
			this.ruleForm.contactParams.splice(index,1)
		},
		// 点击添加联系人
		clickAddPersonal() {
			let {contactPhone,positionName,userName} = this.addpersoninfo
			if (!userName) {
				return this.$myMessage('请填写联系人姓名','error')
			}
			let info = JSON.parse(JSON.stringify(this.addpersoninfo))
			this.ruleForm.contactParams.push(info)
			this.addpersoninfo = {
				contactId: '', //联系人id
				contactPhone: '', //联系人手机号
				positionName: '', //联系人职务
				userName: '' //联系人姓名
			}
		},
		// 选择线索来源
		chooseClues(item) {
			this.ruleForm.customerSource = item.settingId;
		},
		// 选择公司类型
		chooseCompanyType(item) {
			this.ruleForm.companyType = item.settingId;
		},

		submitForm() {
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					// console.log(this.ruleFrom);
					// return;
					this._editCustomerInfo();
				} else {
					return false;
				}
			});
		},
		resetForm() {
			this.$refs['ruleForm'].resetFields();
			this.drawer = false;
		}
	}
};
</script>

<style scoped lang="scss">
::v-deep {
	.el-form-item__content {
		line-height: 20px;
	}
	.inputbox {
		.el-input__inner {
			width: 600px !important;
			height: 40px !important;
		}
	}

	.el-form-item {
		margin: 0;
	}
}
.addinputbox {
	display: flex;
	align-items: center;
	margin: 15px 0 0 0;
	padding: 0 0 0 17px;
	span {
		font-size: 14px;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #444444;
	}
	.el-input {
		width: 400px !important;
		height: 40px;
		border-radius: 4px;
		
	}
	.el-input__inner {
		width: 400px !important;
		height: 40px;
		border-radius: 4px;
		
	}
}
.onerelation {
	padding: 0 0 0 17px;
	display: flex;
	align-items: center;
	margin: 0 0 20px 0;
	span {
		font-size: 14px;
		color: #aeaeae;
		margin: 0 10px 0 0;
	}
	i {
		cursor: pointer;
	}
	p {
		margin: 0 20px 0 0;
		font-size: 14px;
		color: #333333;
	}
}
.formbox {
	width: 100%;
	padding: 30px 40px;
	input:focus {
		border: 1px solid #4d91ff;
	}
	input {
		padding: 0 20px;
		width: 100%;
		outline: none;
		border: 1px solid #eeeeee;
		height: 40px;
		font-size: 14px;
		border-radius: 4px;
	}
}
.inputtitle {
	margin: 0 0 15px 0;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #666666;
	span {
		color: #ff0000;
		margin: 0 10px 0 0;
	}
}
.oneway {
	cursor: pointer;
	margin: 0 30px 10px 0;
	display: flex;
	align-items: center;
	div {
		width: 14px;
		height: 14px;
		background: #ffffff;
		border: 1px solid #cccccc;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 10px 0 0;
		&.activeway {
			border: 1px solid #2e77f6;
		}
		p {
			width: 8px;
			background-color: #2e78f6;
			height: 8px;
			border-radius: 50%;
		}
	}
}
.allway {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 0 0 17px;
}
::v-deep {
	.el-input__inner {
		width: 520px !important;
		height: 40px !important;
	}
}
.twobtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 70px 0 0 0;
	div:active {
		opacity: 0.6;
	}
	div {
		cursor: pointer;
		width: 180px;
		height: 40px;
		background: #f7f7f7;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #999999;
		&.save {
			background: linear-gradient(270deg, #2d74f4 0%, #3286ff 100%);
			color: #ffffff;
			margin: 0 0 0 50px;
		}
	}
}
textarea:focus {
	border: 1px solid #4d91ff;
}
textarea {
	font-size: 14px;
	outline: none;
	padding: 13px 20px;
	width: 100%;
	height: 90px;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #eeeeee;
	max-width: 100%;
	min-width: 100%;
	min-height: 50px;
}
.titlebox {
	border-bottom: 1px solid #eeeeee;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 52px;
	padding: 0 20px;
	i {
		cursor: pointer;
	}
	span {
		font-size: 1.125rem;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #303030;
	}
}
::v-deep {
	.el-drawer {
		width: 700px !important;
		border-radius: 10px 0px 0px 10px !important;
	}
}
</style>
